import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from "echarts/components";

use([
  TitleComponent,
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent
]);
