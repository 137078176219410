<template>
  <div
    v-if="!isPaperlessEnabled && !isDismissPaperlessAuditExists"
    class="message-block"
  >
    <b-alert variant="warning" show class="warn-message">
      <strong>Opt-in to paperless billing</strong>
      <div>
        Enable paperless billing on this apartment and any other credit billing
        accounts for e-mail notification of bills instead of postal bills
      </div>
      <div style="margin-top: 10px;">
        <b-button
          class="action"
          size="sm"
          @click="paperlessOptIn"
          variant="success"
          >Opt In</b-button
        >
      </div>
      <div style="margin-top: 10px;">
        <b-button
          class="action"
          size="sm"
          @click="dismissPaperlessOptIn"
          variant="success"
          >Dismiss</b-button
        >
      </div>
    </b-alert>
  </div>
</template>

<script>
import { paperlessOptIn } from "../../../components/mixins/paperless";

export default {
  name: "PaperlessMessageBlock",
  mixins: [paperlessOptIn],
  props: {
    reference: {
      type: String,
      required: true
    },
    isPaperlessEnabled: {
      type: Boolean
    },
    isDismissPaperlessAuditExists: {
      type: Boolean
    }
  },
  methods: {
    updateParent() {
      this.$emit("dashboard_refresh");
    },
    dismissPaperlessOptIn() {
      this.$swal({
        title: "Dismiss Paperless Opt In?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
        showLoaderOnConfirm: true,
        customClass: {
          input: "d-none"
        },
        preConfirm: () => {
          const id = this.reference;
          return this.$store
            .dispatch("dismissPaperlessOptIn", { id })
            .then(() => {
              const title = "Paperless Opt In Dismissed";
              const subtitle = "Message successfully dismissed from dashboard";
              this.$store.dispatch("pushSuccess", { title, subtitle });
              this.updateParent();
            })
            .catch(err => {
              const title = "Invalid data";
              const subtitle = "There was a problem storing the data: " + err;
              const samePage = true;
              this.$store.dispatch("pushError", { title, subtitle, samePage });
            })
            .finally(() => {
              this.submitting = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.message-block {
  margin-top: 20px;
}

.warn-message {
  div {
    font-size: 0.9rem;
    padding: 5px 0px;
  }
  div.date {
    font-size: 0.8rem;
    padding-top: 0px;
  }
}
</style>
