<template>
  <div>
    <DownloadList
      v-if="welcomePackDownloadsList.length > 0"
      :reference="reference"
      :download-list="welcomePackDownloadsList"
    ></DownloadList>

    <DownloadList
      v-if="tariffCardDownloadsList.length > 0"
      :reference="reference"
      :download-list="tariffCardDownloadsList"
    ></DownloadList>

    <DownloadList
      v-if="genericDownloadsList.length > 0"
      :reference="reference"
      :download-list="genericDownloadsList"
    ></DownloadList>
  </div>
</template>

<script>
import DownloadList from "./DownloadList";

export default {
  name: "BillingDownloads",
  props: {
    reference: {
      type: String,
      required: true
    },
    customerDownloads: {
      type: Object,
      required: true
    }
  },
  components: {
    DownloadList
  },
  data() {
    return {
      welcomePackDownloadsList: [],
      tariffCardDownloadsList: [],
      genericDownloadsList: []
    };
  },
  created() {
    this.setup();
  },
  methods: {
    setup() {
      this.welcomePackDownloadsList =
        "welcome_packs" in this.customerDownloads
          ? this.customerDownloads.welcome_packs
          : [];

      this.tariffCardDownloadsList =
        "tariff_cards" in this.customerDownloads
          ? this.customerDownloads.tariff_cards
          : [];

      this.genericDownloadsList =
        "generic" in this.customerDownloads
          ? this.customerDownloads.generic
          : [];
    }
  }
};
</script>
