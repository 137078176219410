export const date = {
  methods: {
    formatDate(givenDate, isReverse, withTime) {
      let date = givenDate ? new Date(givenDate) : new Date();
      let dd = date.getDate();

      let mm = date.getMonth() + 1;
      let yyyy = date.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      let hour = date.getHours();
      let minutes = date.getMinutes();

      if (hour < 10) {
        hour = "0" + hour;
      }

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      let time = "";
      if (withTime) {
        time = " " + hour + ":" + minutes;
      }

      if (isReverse) {
        return dd + "-" + mm + "-" + yyyy + time;
      }

      return yyyy + "-" + mm + "-" + dd + time;
    }
  }
};
