<template>
  <div v-if="dashboardLoaded">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>{{ dashboard.apartment }}</h1>

          <MessageBlock :messages="messages"></MessageBlock>

          <PaperlessMessageBlock
            @dashboard_refresh="fetchData"
            :reference="payRef"
            :isPaperlessEnabled="
              dashboard.customerPaperlessData.isPaperlessEnabled
            "
            :isDismissPaperlessAuditExists="
              dashboard.isDismissPaperlessAuditExists
            "
          ></PaperlessMessageBlock>
        </div>
      </div>

      <div
        v-if="
          Object.keys(dashboard.transactions.items).length > 0 ||
            Object.keys(dashboard.customer_downloads).length > 0
        "
      >
        <b-button
          size="sm"
          class="sub-nav-button"
          v-bind:disabled="pageSelection === 'dashboard'"
          @click="selectPage('dashboard')"
          >My Dashboard</b-button
        >
        <b-button
          v-if="Object.keys(dashboard.transactions.items).length > 0"
          size="sm"
          class="sub-nav-button"
          v-bind:disabled="pageSelection === 'transactions'"
          @click="selectPage('transactions')"
          >My Transactions</b-button
        >
        <b-button
          v-if="Object.keys(dashboard.customer_downloads).length > 0"
          size="sm"
          class="sub-nav-button"
          v-bind:disabled="pageSelection === 'downloads'"
          @click="selectPage('downloads')"
          >My Correspondence</b-button
        >
      </div>

      <div v-if="pageSelection === 'transactions'">
        <BillingTransactions
          @update-transactions="updateTransactions"
          :reference="payRef"
          :balance="dashboard.account_balance"
          :transactions="dashboard.transactions"
          :bills="dashboard.bills"
          :credits="dashboard.credits"
        ></BillingTransactions>
      </div>

      <div v-if="pageSelection === 'downloads'">
        <BillingDownloads
          :reference="payRef"
          :customerDownloads="dashboard.customer_downloads"
        ></BillingDownloads>
      </div>

      <div v-if="pageSelection === 'dashboard'">
        <div class="row">
          <div class="col-md-12">
            <h2>Your Utilities</h2>
          </div>
          <div
            class="col-lg-2 col-md-3 col-6"
            v-bind:key="item.id"
            v-for="item in dashboard.utilities"
            @click="changeUtility(item.id, item.reference, '')"
          >
            <UtilityBlock
              :id="item.id"
              :active-utility="activeUtility"
              :is-estimate="item.is_last_estimate"
              :read="item.last_value"
              :utility="item.type"
              :is-disabled="!allowChangeUtility"
            ></UtilityBlock>
          </div>
        </div>

        <div
          class="col-md-12 show-archive-toggle mb-4"
          v-if="dashboard.archived_utilities.length > 0"
        >
          <b-form-checkbox v-model="archivedMetersShow" switch size="lg"
            >Show archived meters</b-form-checkbox
          >
        </div>

        <div class="row" v-if="archivedMetersShow">
          <div class="col-md-12">
            <h2>Archived Utilities</h2>
          </div>
          <div
            class="col-lg-2 col-md-3 col-6"
            v-bind:key="item.id"
            v-for="item in dashboard.archived_utilities"
            @click="changeUtility(item.id, item.reference, item.meter_ends)"
          >
            <UtilityBlock
              :id="item.id"
              :active-utility="activeUtility"
              :is-estimate="item.is_last_estimate"
              :read="item.last_value"
              :utility="item.type"
              :is-disabled="!allowChangeUtility"
            ></UtilityBlock>
          </div>
        </div>

        <CurrentTariff
          :tariff="dashboard.tariffs[activeUtility]"
        ></CurrentTariff>

        <ReadData
          @refresh-complete="utilityUpdateComplete"
          v-if="shouldShowReadData"
          :id="payRef"
          :utility="activeUtilityRef"
          :initial-data="dashboard.initialReadData"
          :archived-meter-end-date="activeMeterEndDate"
        ></ReadData>

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <h2>Account Overview</h2>

            <p class="sub-text prominent" v-if="isLease">
              <span v-if="dashboard.account_balance.is_in_credit"
                >Your account balance is
              </span>
              <span v-else>You currently owe </span>
              <strong
                >&pound;{{ dashboard.account_balance.balance
                }}<span
                  class="balance-credit"
                  v-if="dashboard.account_balance.is_in_credit"
                  >CR</span
                ></strong
              >
              <span class="note">
                (Updated {{ dashboard.account_balance.updated_at }})
              </span>
            </p>

            <p class="sub-text prominent" v-if="!isLease && lastBillTotal">
              Your last bill payable was
              <strong>&pound;{{ lastBillTotal }}</strong>
            </p>

            <h5 class="sub-head">Recent Transactions</h5>

            <div class="unavailable-box" v-if="dashboard.payments.length === 0">
              No recent transactions available
            </div>

            <div v-else>
              <p class="sub-text">
                Only payments made online will show here.
              </p>

              <div
                class="block tabular payment"
                v-bind:key="item.id"
                v-for="item in dashboard.payments"
              >
                <div class="row">
                  <div class="col-2">
                    <span class="icon" style="position:absolute; left: 0px;">
                      <font-awesome-icon
                        v-if="item.arrangement.method === 'cash'"
                        icon="money-bill-alt"
                      />
                      <font-awesome-icon
                        v-if="item.arrangement.method === 'card'"
                        icon="credit-card"
                      />
                    </span>
                  </div>
                  <div class="col-6">
                    <div class="head">
                      <span v-if="item.arrangement.method === 'cash'">Shop</span
                      ><span v-else>Online</span> Payment
                    </div>
                    <div class="sub">
                      {{ item.transacted_at | moment("MMMM Do YYYY HH:mm") }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="price">£{{ item.payment }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12" id="payment-element">
            <h2>Make Payment</h2>
            <div
              class="block tabular"
              style="padding: 20px; position: relative;"
            >
              <div v-if="dashboard.account">
                <p
                  v-if="!paymentComplete"
                  style="text-align: left; font-size: 0.9rem;"
                >
                  Enter the amount you wish to pay and optionally the reference
                  number of the bill this payment relates to (this is found
                  underneath the Account Number on your Bill).
                </p>

                <p class="payment-requests">
                  <strong>Account Number: </strong> {{ dashboard.account }}
                </p>

                <div v-if="paymentComplete">
                  <b-alert variant="success" show>
                    <strong>Payment Complete</strong>
                    <div>
                      We have received your payment and are updating your
                      balance
                    </div>
                    <div style="margin-top: 10px;">
                      <b-button
                        class="action"
                        size="sm"
                        @click="resetPayment"
                        variant="success"
                        >Make further payment</b-button
                      >
                    </div>
                  </b-alert>
                </div>

                <div
                  v-if="
                    !stripeElementsSecret &&
                      !paymentComplete &&
                      dashboard.account
                  "
                >
                  <div
                    class="row"
                    style="padding-top: 0px; padding-bottom: 0px;"
                  >
                    <div class="col-lg-12">
                      <b-input-group class="payment-input">
                        <b-form-input
                          size="sm"
                          v-model="requestReference"
                          placeholder="Bill Reference Number (Optional)"
                          type="text"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col-md-12" style="margin-top: 20px;">
                      <b-input-group
                        size="lg"
                        prepend="£"
                        class="payment-input"
                      >
                        <b-form-input
                          placeholder="Amount"
                          v-model="requestAmount"
                          type="number"
                          step="0.01"
                          min="0.01"
                          max="500.00"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>

                  <div class="payment-button">
                    <b-button
                      v-on:click="requestPayment"
                      v-if="!paymentLoading"
                      variant="outline-info"
                      >Continue</b-button
                    >
                    <div v-if="paymentLoading">
                      <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </div>
                  </div>

                  <PaymentNotes></PaymentNotes>
                </div>

                <div v-if="stripeElementsSecret && !paymentComplete">
                  <p v-if="requestReference" class="payment-requests">
                    <strong>Bill Reference Number: </strong>
                    {{ requestReference }}
                  </p>

                  <div
                    style="max-width: 600px; border: 1px solid #bbb; transition: box-shadow .2s ease,-webkit-box-shadow .2s ease; padding: 5px;"
                    id="card-element"
                  ></div>

                  <div class="payment-button">
                    <b-button
                      v-on:click="purchase"
                      variant="outline-success"
                      v-if="!paymentLoading"
                      >Pay £{{ requestAmount }}</b-button
                    >
                    <div v-if="paymentLoading">
                      <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <p style="text-align: left; font-size: 0.9rem;">
                  We are unable to take payments from this account
                </p>
              </div>
            </div>
          </div>
        </div>

        <ActionsAndHelp
          @dashboard_refresh="fetchData"
          :is-lease="isLease"
          :account="dashboard.account"
          :development="dashboard.development_encoded"
          :reference="payRef"
          :last-statement="dashboard.last_statement"
        ></ActionsAndHelp>

        <PaperlessBilling
          @dashboard_refresh="fetchData"
          :reference="payRef"
          :customerAccountType="dashboard.secondary_type"
          :customerPaperlessData="dashboard.customerPaperlessData"
          :isDissmisPaperlessAuditExists="
            dashboard.isDismissPaperlessAuditExists
          "
          :activeBillingAccounts="dashboard.activeBillingAccounts"
        >
        </PaperlessBilling>

        <CurrentBills
          :reference="payRef"
          :bills="dashboard.bills"
          :credits="dashboard.credits"
          :chases="dashboard.chases"
        ></CurrentBills>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import CurrentBills from "@/views/Billing/Components/CurrentBills";
import UtilityBlock from "@/views/Components/Dashboard/UtilityBlock";
import MessageBlock from "@/views/Components/Global/MessageBlock";
import PaperlessMessageBlock from "@/views/Components/Global/PaperlessMessageBlock";
import ReadData from "@/views/Billing/Components/ReadData";
import CurrentTariff from "@/views/Billing/Components/CurrentTariff";
import ActionsAndHelp from "@/views/Billing/Components/ActionsAndHelp";
import PaperlessBilling from "./Components/PaperlessBilling";
import PaymentNotes from "@/views/Shared/PaymentNotes";
import BillingTransactions from "@/views/Billing/Components/BillingTransactions";
import BillingDownloads from "./Components/BillingDownloads";

library.add(fas);

export default {
  name: "BillingDashboard",
  props: ["payRef"],
  components: {
    BillingDownloads,
    BillingTransactions,
    PaymentNotes,
    ActionsAndHelp,
    CurrentTariff,
    ReadData,
    MessageBlock,
    PaperlessMessageBlock,
    FontAwesomeIcon,
    CurrentBills,
    UtilityBlock,
    PaperlessBilling
  },
  computed: {
    env() {
      return process.env.VUE_APP_ENV;
    },
    lastBillTotal() {
      if (this.dashboard.bills.length === 0) {
        return null;
      }

      return this.dashboard.bills[0].bill_total;
    },
    isLease() {
      return this.dashboard.secondary_type === "lease";
    },
    shouldShowReadData() {
      return !(
        this.dashboard.secondary_type === "lease" &&
        this.dashboard.is_active_sub_lease
      );
    },
    messages() {
      return this.dashboard.messages;
    }
  },
  created() {
    this.$store.dispatch("loaderCancel");
    this.fetchData();
  },
  mounted: function() {
    this.configureStripe();
  },
  data() {
    return {
      pageSelection: "dashboard",
      allowChangeUtility: true,
      dashboard: {},
      activeUtility: null,
      activeUtilityRef: null,
      activeMeterEndDate: "",
      dashboardLoaded: false,
      minAmount: 1,
      maxAmount: 500,
      stripeAPIToken: process.env.VUE_APP_STRIPE_CREDIT_PUBLIC_KEY,
      stripe: "",
      elements: "",
      card: "",
      requestAmount: "",
      requestReference: "",
      paymentLoading: false,
      stripeElementsSecret: "",
      paymentComplete: false,
      readFields: [
        { key: "user_date", label: "Date", sortable: true },
        { key: "data.read", label: "Read", sortable: true },
        { key: "data.is_estimate", label: "Read Type", sortable: true },
        { key: "data.consumption", label: "Consumption", sortable: true }
      ],
      archivedMetersShow: false
    };
  },
  methods: {
    updateTransactions(transactionData) {
      this.dashboard.transactions = transactionData;
    },
    selectPage(pageName) {
      this.pageSelection = pageName;

      let path = this.$route.path;

      if (pageName === "transactions") {
        this.$gtag.event("viewTransactions", {
          event_category: "billingDashboard"
        });
        path += "/transactions";
      }

      this.$gtag.pageview({ page_path: path });
    },
    utilityUpdateComplete() {
      this.allowChangeUtility = true;
    },
    formatPrice(price) {
      if (price >= 0) {
        return price;
      }

      return Math.abs(price);
    },
    resetPayment() {
      this.requestAmount = "";
      this.requestReference = "";
      this.paymentComplete = false;
    },
    requestPayment() {
      this.paymentLoading = true;

      if (this.requestReference) {
        this.requestReference = this.requestReference.toUpperCase();
      }

      let amount = this.requestAmount;
      let reference = this.requestReference;
      let errorReason = null;

      if (isNaN(amount)) {
        errorReason = "Please enter a valid payment amount";
      }

      if (
        !errorReason &&
        Math.floor(amount) !== amount &&
        amount.toString().split(".")[1] !== undefined &&
        amount.toString().split(".")[1].length > 2
      ) {
        errorReason = "Please enter a valid payment amount";
      }

      if (!errorReason && amount < this.minAmount) {
        errorReason = "The minimum payment is £1.00";
      }

      if (!errorReason && amount > this.maxAmount) {
        errorReason = "The maximum payment is £500.00";
      }

      if (errorReason) {
        const title = "Payment Error";
        const subtitle = errorReason;
        const samePage = true;
        this.$store.dispatch("pushError", { title, subtitle, samePage });
        this.paymentLoading = false;
        return;
      }

      this.requestAmount = amount = parseFloat(amount).toFixed(2);
      const id = this.payRef;

      this.$store
        .dispatch("requestPaymentBilling", { amount, id, reference })
        .then(response => {
          this.$gtag.event("payment", {
            event_category: "paymentRequestedBilling",
            event_label: amount
          });
          this.stripeElementsSecret = response.data.ref;

          this.$nextTick(() => {
            this.card.mount("#card-element");
          });
        })
        .catch(err => {
          const title = "Payment Error";
          const subtitle = err;
          const samePage = true;
          this.$store.dispatch("pushError", { title, subtitle, samePage });
        })
        .finally(() => {
          this.paymentLoading = false;
        });
    },
    configureStripe() {
      this.stripe = window.Stripe(this.stripeAPIToken);

      let style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };

      this.elements = this.stripe.elements();
      this.card = this.elements.create("card", { style: style });
    },
    purchase() {
      this.paymentLoading = true;
      this.stripe
        .confirmCardPayment(this.stripeElementsSecret, {
          payment_method: {
            card: this.card
          }
        })
        .then(result => {
          if (result.error) {
            let gTagCategory = "paymentErrorBillingCard";
            let eventLabel = result.error.message;
            let customerError = result.error.message;

            if (result.error.type !== "card_error") {
              gTagCategory = "paymentErrorBillingApp";
              customerError =
                "We were unable to process this payment. Please contact us if this issue persists.";
            }

            if (result.error.type === "validation_error") {
              customerError = result.error.message;
            }

            if (
              result.error.payment_method &&
              result.error.payment_method.card.brand === "amex"
            ) {
              eventLabel = "amex";
              customerError =
                "We no longer accept American Express Payments. Please use an alternative payment method";
            }

            this.$gtag.event(result.error.type, {
              event_category: gTagCategory,
              event_label: eventLabel
            });
            // Show error to your customer (e.g., insufficient funds)
            const title = "Payment Error";
            const subtitle = customerError;
            const samePage = true;
            this.$store.dispatch("pushError", { title, subtitle, samePage });
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === "succeeded") {
              this.$gtag.event("payment", {
                event_category: "paymentSuccessBilling"
              });
              this.card.clear();
              this.stripeElementsSecret = null;
              this.paymentComplete = true;
              const title = "Payment Processing";
              const subtitle =
                "Your payment will appear on your dashboard shortly";
              const samePage = true;
              this.$store.dispatch("pushSuccess", {
                title,
                subtitle,
                samePage
              });
            }
          }
          this.paymentLoading = false;
        });
    },
    clearCard() {
      this.card.clear();
    },
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function(e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    changeUtility(id, reference, meterEndDate) {
      if (id === this.activeUtility || !this.allowChangeUtility) {
        return;
      }

      if (this.shouldShowReadData) {
        //Keep true if no read data is shown
        this.allowChangeUtility = false;
      }
      this.activeUtility = id;
      this.activeUtilityRef = reference;
      this.activeMeterEndDate = meterEndDate;
    },
    fetchData() {
      const title = "Fetching Data";
      const subtitle = "Compiling Dashboard Data";
      this.$store.dispatch("loaderInitiate", { title, subtitle });

      const id = this.payRef;
      this.$store
        .dispatch("dashboardBillingGet", { id })
        .then(response => {
          this.dashboard = response;
          if (response.utilities.length === 0) {
            throw "Invalid utility";
          }
          this.activeUtility = response.utilities[0].id;
          this.activeUtilityRef = response.utilities[0].reference;
          this.dashboardLoaded = true;
        })
        .catch(err => {
          const title = "Error accessing Dashboard";
          const subtitle = err;
          this.$store.dispatch("pushError", { title, subtitle });

          this.$router.push("/");
        })
        .finally(() => {
          this.$store.dispatch("loaderCancel");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/dashboard.scss";

.notice {
  background: #6b3594;
  color: white;
  background: linear-gradient(45deg, #6b3594, #6b3594b8);
  padding: 10px;
  font-size: 0.9rem;

  h2 {
    text-align: center !important;
    text-transform: uppercase;
    padding-bottom: 5px;
    font-weight: bold;
    font-size: 1.3rem !important;
    margin-top: 5px;
  }

  hr {
    margin-top: 0.8rem;
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  a {
    color: white;
    text-decoration: underline;
  }

  .sub {
    font-size: 0.8rem;
    padding-top: 10px;
  }
}

.payment-input input {
  border-radius: 0;
}

.payment-requests {
  text-align: left;
  font-size: 0.8rem;
}

.tariff-info {
  margin-top: 0px;
  text-align: left;

  h3 {
    font-size: 1.5rem;
  }

  .tariff-item {
    font-size: 0.9rem;
    padding-top: 5px;
  }
}

.meter-table {
  margin-bottom: 0px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.table-key {
  text-align: right;
  font-size: 0.8rem;
  margin-top: 5px;
  color: #666;
  padding-right: 5px;
}

.sub-head {
  text-align: left;
  padding-left: 10px;
}

.sub-text {
  font-size: 0.9rem;
  text-align: left;
  padding-left: 10px;

  &.prominent {
    font-size: 1rem;

    strong {
      font-size: 1.2rem;
    }

    .note {
      font-size: 0.8rem;
    }
  }
}

.sub-nav-button {
  margin: 10px 15px;
  padding: 10px;

  &.disabled {
    cursor: not-allowed;
  }
}

.balance-credit {
  font-size: 0.8rem;
  padding-left: 4px;
}

.show-archive-toggle {
  padding-left: 0;
  text-align: left;
}
</style>
