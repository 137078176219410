<template>
  <div>
    <div class="row" v-if="!tariff.is_archived">
      <div class="col-md-12">
        <h3>Tariff Information</h3>
      </div>

      <div class="col-md-3 col-sm-12 tariff-item">
        <div class="title">Unit Cost</div>
        <div v-if="tariff.unit_cost" class="value">
          {{ tariff.unit_cost }}p per {{ tariff.unit }}
        </div>
        <div v-else class="value">-</div>
      </div>
      <div
        class="col-md-3 col-sm-12 tariff-item"
        v-if="tariff.standing_charge > 0"
      >
        <div class="title">Standing Charge</div>
        <div class="value">{{ tariff.standing_charge }}p per day</div>
      </div>
      <div class="col-md-3 col-sm-12 tariff-item" v-else>
        <div class="title">Standing Charge</div>
        <div class="value">-</div>
      </div>
      <div
        class="col-md-3 col-sm-12 tariff-item"
        v-if="tariff.admin_charge > 0"
      >
        <div class="title">Admin Charge</div>
        <div class="value">{{ tariff.admin_charge }}p per day</div>
      </div>
      <div class="col-md-3 col-sm-12 tariff-item" v-else>
        <div class="title">Admin Charge</div>
        <div class="value">-</div>
      </div>
      <div class="col-md-3 col-sm-12 tariff-item">
        <div class="title">Tariff Ends</div>
        <div v-if="tariff.ends" class="value">{{ tariff.ends }}</div>
        <div v-else class="value">-</div>
      </div>
    </div>
    <b-alert v-else variant="warning" show class="warn-message">
      No tariff information available for archived meter. Meter archived on
      {{ tariff.ends | moment("DD/MM/YYYY") }}
    </b-alert>
  </div>
</template>

<script>
import { utilities } from "@/components/mixins/utilities";

export default {
  name: "CurrentTariff",
  mixins: [utilities],
  props: {
    tariff: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  text-align: left;
}

h3 {
  font-size: 1.3rem;
}

.tariff-item {
  .title {
    font-size: 0.8rem;
  }

  .value {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.warn-message {
  color: black;
  text-align: center;
}
</style>
