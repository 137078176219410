<template>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="sticky-top">
        <div class="balance-block">
          <div class="header">Account Balance</div>
          <div class="balance">&pound;{{ balance.balance }}</div>
          <div class="balance-state" v-if="balanceNotZero">
            <span v-bind:class="{ credit: balance.is_in_credit }">{{
              creditText
            }}</span>
          </div>
          <div class="updated">
            Balance last updated:
            <span class="highlight">{{ balance.updated_at }}</span>
          </div>
        </div>

        <div class="header">Download Transactions</div>

        <b-button @click="downloadTransactions" class="btn-de" size="sm"
          >Download Listed Transactions</b-button
        >

        <div class="updated">
          Download will include transactions listed.
          <span v-if="lastKey"
            >More transactions can be loaded under the transactions list</span
          >
        </div>

        <vue-json-to-csv
          ref="transactionDownload"
          :csv-title="getDownloadName"
          :labels="downloadLabels"
          :json-data="downloadableTransactions"
          ><span></span>
        </vue-json-to-csv>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="transaction-list">
        <div v-for="(transaction, index) in transactionList" :key="index">
          <div class="header" v-if="showHeader(transaction, index)">
            {{ transaction.key }}
          </div>
          <div class="item">
            <div class="date-block">
              <div class="day">{{ transaction.day }}</div>
              <div class="date">{{ transaction.date }}</div>
            </div>

            <div class="detail-block">
              <div class="primary">{{ transaction.type }}</div>
              <div
                class="secondary"
                v-bind:class="{ 'is-link': isBillDownload(transaction) }"
                @click="triggerBillDownload(transaction)"
              >
                <font-awesome-icon
                  icon="download"
                  v-if="isBillDownload(transaction)"
                />
                {{ transaction.secondary }}
              </div>
            </div>

            <div
              class="amount-block"
              v-bind:class="{ 'is-debit': !transaction.is_debit }"
            >
              &pound;{{ transaction.amount }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="lastKey">
        <b-button
          v-bind:disabled="disableDownloads"
          size="sm"
          @click="getMoreTransactions"
          variant="info"
          >Load more</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import VueJsonToCsv from "vue-json-to-csv";
import { billDownload } from "@/components/mixins/bill-download";
library.add(fas);
export default {
  name: "BillingTransactions",
  props: {
    reference: {
      type: String,
      required: true
    },
    transactions: {
      type: Object,
      required: true
    },
    balance: {
      type: Object,
      required: true
    },
    bills: {
      type: Array,
      required: true
    },
    credits: {
      type: Array,
      required: true
    }
  },
  mixins: [billDownload],
  components: {
    VueJsonToCsv,
    FontAwesomeIcon
  },
  data() {
    return {
      transactionList: [],
      lastKey: null,
      sage: "transaction-list",
      downloadLabels: {
        amount: { title: "Amount" },
        full_date: { title: "Date" },
        type: { title: "Transaction Type" },
        secondary: { title: "Narrative" }
      }
    };
  },
  computed: {
    creditText() {
      return this.balance.is_in_credit ? "In Credit" : "In Debit";
    },
    balanceNotZero() {
      return this.balance.balance < 0 || this.balance.balance > 0;
    },
    getDownloadName() {
      return this.sage + "-transactions.csv";
    },
    downloadableTransactions() {
      return this.transactionList.map(obj => {
        if (!obj.is_debit) {
          return { ...obj, amount: -Math.abs(obj.amount) };
        }
        return obj;
      });
    }
  },
  created() {
    this.setup();
  },
  methods: {
    updateTransactions(transactions) {
      this.$emit("update-transactions", transactions);
    },
    isBillDownload(transaction) {
      return !!this.validateBill(transaction);
    },
    downloadTransactions() {
      this.$gtag.event("downloadTransactions", {
        event_category: "billingDashboard"
      });

      this.$nextTick(() => {
        this.$refs.transactionDownload.$el.click();
      });
    },
    triggerBillDownload(transaction) {
      const bill = this.validateBill(transaction);

      if (!bill) {
        return;
      }

      let isCredit = false;
      if (transaction.type === "Charging Statement Reversal") {
        isCredit = true;
      }

      this.downloadBill(bill.id, bill.end_file, this.reference, isCredit);
    },
    validateBill(transaction) {
      const allowedTypes = [
        "Charging Statement",
        "Charging Statement Reversal"
      ];
      if (this.disableDownloads || !allowedTypes.includes(transaction.type)) {
        return null;
      }

      if (transaction.type === "Charging Statement") {
        return this.bills.find(o => o.invoice_number === transaction.secondary);
      }

      return this.credits.find(o => o.invoice_number === transaction.secondary);
    },
    showHeader(value, index) {
      if (index === 0) {
        return true;
      }

      return value.key !== this.transactionList[index - 1].key;
    },
    setup() {
      this.transactionList = this.transactions.items;
      this.lastKey = this.transactions.last_key;
      this.sage = this.transactions.account;
    },
    getMoreTransactions() {
      this.disableDownloads = true;

      if (!this.lastKey) {
        return;
      }

      const data = {
        lastKey: this.lastKey
      };

      this.$store
        .dispatch("dashboardBillingTransactions", {
          id: this.reference,
          data: data
        })
        .then(response => {
          this.$gtag.event("requestTransactions", {
            event_category: "billingDashboard"
          });

          this.transactionList = this.transactionList.concat(response.items);
          this.lastKey = response.last_key;

          this.updateTransactions({
            items: this.transactionList,
            last_key: this.lastKey,
            account: this.sage
          });
        })
        .catch(err => {
          const title = "Problem getting transaction data";
          const subtitle = err;
          const samePage = true;
          this.$store.dispatch("pushError", { title, subtitle, samePage });
        })
        .finally(() => {
          this.disableDownloads = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #777;
  margin-bottom: 0px;
}

.updated {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 20px;
  margin-top: 8px;

  span.highlight {
    font-weight: bold;
  }
}

.balance {
  margin-top: -5px;
  font-size: 1.8rem;
  font-weight: bold;
}

.balance-state span {
  margin-top: -5px;
  font-size: 0.7rem;
  background-color: #ffb900;
  color: #222;
  padding: 2px 4px;
  border-radius: 5px;

  &.credit {
    background-color: #99e6ff;
  }
}

.transaction-list {
  margin-bottom: 20px;

  .header {
    font-weight: bold;
    font-size: 0.8rem;
    text-align: left;
    margin-bottom: 3px;
  }

  .item {
    font-size: 0.9rem;
    text-align: left;
    color: #777;
    position: relative;
    background-color: white;
    border: 1px solid #dedede;
    padding: 8px;
    border-radius: 8px;
    margin: 5px 0px 10px;

    .date-block {
      position: absolute;
      left: 8px;
      top: 8px;

      .date {
        color: #111;
        font-weight: bold;
        font-size: 1.5rem;
        margin-top: -7px;
        text-align: center;
      }

      .day {
        text-transform: uppercase;
        font-size: 0.7rem;
        text-align: center;
      }
    }

    .detail-block {
      padding-left: 50px;
      padding-right: 95px;

      .primary {
        color: #222;
        font-weight: bold;
        font-size: 1rem;
      }

      .secondary {
        margin-top: 0px;
        font-weight: bold;
        color: #999;
        font-size: 0.8rem;
        min-height: 19px;

        &.is-link {
          cursor: pointer;
          color: $dePurple;
        }
      }
    }

    .amount-block {
      position: absolute;
      right: 8px;
      top: 8px;
      font-weight: bold;
      font-size: 1.2rem;
      color: #222;

      &.is-debit {
        color: green;

        &:before {
          content: "-";
        }
      }
    }
  }
}
</style>
