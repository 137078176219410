<template>
  <div class="row" v-if="shouldLoad">
    <div class="col-md-12">
      <div class="block" style="position: relative;">
        <div
          style="position: absolute; z-index: 10; right: 15px; top: 15px; text-align: right;"
        >
          <b-button @click="isHidden = !isHidden" class="btn-de" size="sm"
            ><span v-if="isHidden">Change Month</span
            ><span v-else>Close</span></b-button
          >

          <div style="margin-top: 5px;">
            <month-picker
              :no-default="true"
              :min-date="validFrom"
              :max-date="today"
              v-if="!isHidden"
              @input="dateInput"
            ></month-picker>
          </div>
        </div>
        <div @click="hideDate">
          <v-chart
            class="chart"
            :option="bar"
            ref="bar"
            autoresize
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
          />
        </div>

        <div class="download-header">Download Meter Raw Data</div>

        <b-button @click="downloadReads" class="btn-de" size="sm"
          >Download Data</b-button
        >

        <div class="data-available">Data available from {{ dataFromDate }}</div>

        <vue-json-to-csv
          ref="readDownload"
          :csv-title="getDownloadName(false)"
          :labels="downloadLabels"
          :json-data="readDownloadData"
          ><span></span>
        </vue-json-to-csv>
      </div>
    </div>
  </div>
</template>

<script>
import "@/plugins/echarts";

import VChart from "vue-echarts";

import { MonthPicker } from "vue-month-picker";
import VueJsonToCsv from "vue-json-to-csv";
import { utilities } from "@/components/mixins/utilities";
import { stringFunctions } from "@/components/mixins/string-functions";
import { date } from "@/components/mixins/date";

export default {
  name: "ReadData",
  mixins: [utilities, stringFunctions, date],
  props: {
    id: {
      type: String,
      required: true
    },
    utility: {
      type: String,
      required: true
    },
    archivedMeterEndDate: {
      type: String,
      required: true
    },
    initialData: {
      type: Object,
      required: true
    }
  },
  watch: {
    utility() {
      this.changeUtility();
    }
  },
  components: {
    VChart,
    MonthPicker,
    VueJsonToCsv
  },
  computed: {
    today() {
      return new Date();
    },
    dataFromDate() {
      return this.formatDate(this.globalFrom, true);
    },
    validFrom() {
      const date = new Date(this.globalFrom);

      date.setDate(1);

      return date;
    },
    globalFrom() {
      if (this.initialData.validFrom) {
        return this.initialData.validFrom;
      }

      return null;
    }
  },
  data() {
    return {
      shouldLoad: false,
      downloadLabels: {
        date: { title: "Read Date" },
        read: { title: "Read Value" },
        isEstimate: { title: "Is Estimated" },
        consumption: { title: "Consumption" }
      },
      isHidden: true,
      unit: null,
      date: {
        from: null,
        to: null,
        month: null,
        year: null
      },
      month: "",
      bar: {
        grid: {
          left: "3%",
          right: "3%",
          top: "10%",
          bottom: "10%",
          containLabel: true
        },
        title: {
          left: "center",
          text: "April 2023",
          subtext: "Consumption",
          itemGap: 2
        },
        tooltip: {
          trigger: "axis",
          formatter: params => {
            if (
              (typeof params[0].value === "undefined" ||
                params[0].value === null) &&
              !params[0].data.isMissing
            ) {
              return;
            }
            if (params[0].data.isMissing) {
              return `<strong>${params[0].name} ${this.month}</strong><br />
                <strong>Missing data</strong><br><span style="font-size: 0.7rem; font-weight: bold; color: #888;">This can be expected if out of signal</span>`;
            }

            let response = `${params[0].data.day} ${params[0].name} ${
              this.month
            }<br />
              <strong>${params[0].value} ${this.getElement(
              "unit",
              this.unit
            )}</strong>`;

            if (params[0].data.isEstimated) {
              response = `${response} <br /><span style="font-size: 0.7rem; font-weight: bold; color: #888;">This is an estimate</span>`;
            }

            return response;
          }
        },
        xAxis: {
          type: "category",
          data: []
        },
        yAxis: {
          name: "Heat [kWh]",
          type: "value"
        },
        series: [
          {
            name: "Data",
            color: ["#83C45D"],
            data: [],
            type: "bar"
          }
        ]
      },
      barLoading: false,
      barLoadingOptions: {
        text: "Loading…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)"
      },
      readDownloadData: []
    };
  },
  created() {
    this.initiate();
  },
  methods: {
    refreshComplete() {
      this.$emit("refresh-complete");
    },
    getDownloadName() {
      return this.stringToSlug(this.bar.title.text);
    },
    downloadReads() {
      this.$gtag.event("downloadReads", {
        event_category: "billingDashboard"
      });

      this.$nextTick(() => {
        this.$refs.readDownload.$el.click();
      });
    },
    changeUtility() {
      let date = new Date();

      if (this.archivedMeterEndDate !== "") {
        date = new Date(this.archivedMeterEndDate);
        this.refresh(date.getMonth() + 1, date.getFullYear());
      }

      this.refresh(date.getMonth() + 1, date.getFullYear());
    },
    hideDate() {
      this.isHidden = true;
    },
    dateInput(date) {
      if (
        this.date.monthIndex === date.monthIndex &&
        this.date.year === date.year
      ) {
        this.isHidden = true;
        return;
      }
      this.date = date;
      this.isHidden = true;

      this.refresh(this.date.monthIndex, this.date.year);
    },
    initiate() {
      this.loadData(this.initialData);
    },
    loadData(data) {
      if (data.subAccountActive) {
        return;
      }
      this.shouldLoad = true;
      this.barLoading = true;
      this.month = data.name;
      this.unit = data.unit;
      this.bar.xAxis.data = data.axisKeys;
      this.bar.yAxis.name = this.getElement("unit", this.unit);
      this.bar.title.text = `${data.fullName} ${this.getElement(
        "name",
        this.unit
      )}`;
      this.readDownloadData = [];

      let graphData = [];
      let maxValue = 2;

      const missingData = {
        color: "#a90000"
      };

      const estimatedData = {
        color: "#ccc"
      };

      Object.entries(data.reads).forEach(([dateKey, value]) => {
        let style = {};

        if (value.is_missing) {
          style = missingData;
        }

        if (!value.is_missing && value.is_estimate) {
          style = estimatedData;
        }

        graphData.push({
          value: value.is_missing ? 0.5 : value.consumption,
          day: value.day_of_week,
          isMissing: value.is_missing,
          isEstimated: value.is_estimate,
          itemStyle: style
        });

        if (!value.is_missing && value.consumption !== null) {
          this.readDownloadData.push({
            date: data.monthPrefix + "-" + dateKey,
            read: value.read_value,
            isEstimate: value.is_estimate ? "Y" : "",
            consumption: value.consumption
          });
        }

        if (value.consumption && maxValue < value.consumption) {
          maxValue = value.consumption;
        }
      });

      this.bar.yAxis.max = Math.ceil(maxValue + 2);
      this.bar.series[0].data = graphData;
      this.barLoading = false;
    },

    refresh(month, year) {
      this.barLoading = true;

      const data = {
        month: month,
        year: year,
        utilityReference: this.utility
      };

      this.$store
        .dispatch("dashboardBillingReads", { id: this.id, data: data })
        .then(response => {
          this.$gtag.event("requestReads", {
            event_category: "billingDashboard"
          });

          this.loadData(response);
        })
        .catch(err => {
          const title = "Problem getting read data";
          const subtitle = err;
          const samePage = true;
          this.$store.dispatch("pushError", { title, subtitle, samePage });
        })
        .finally(() => {
          this.barLoading = false;
          this.refreshComplete();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.chart {
  height: 500px;
  padding-top: 40px;
}

.download-header {
  font-weight: bold;
  font-size: 1rem;
}

.data-available {
  color: #777;
  font-size: 0.8rem;
  padding-top: 20px;
}
</style>
